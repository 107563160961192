import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import { OktaToken } from "./okta-token.model";

const oktaToken: OktaToken | undefined = localStorage.getItem("okta-token-storage") ? JSON.parse(localStorage.getItem("okta-token-storage")) : undefined;
const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  async loadApp({ name }) {
    if (!oktaToken?.idToken || !oktaToken?.accessToken) {
      return System.import("@ross/mof-oms-login-mf");
    } else {
      return System.import(name);
    }
  }
});
const layoutEngine = constructLayoutEngine({ routes, applications });
applications.forEach(registerApplication);
layoutEngine.activate();
start({
  urlRerouteOnly: true,
});